import { Link } from "react-router-dom";

import "./nav.css";

import location from "../../images/location.svg";
import Home from "../home/home";

function Nav() {
  return (
    <>
      <nav>
        <ul>
          <Link to="/" element={<Home />}>
            <span>
              <img src={location} alt="Location marker" />
              <li>Travellog</li>
            </span>
          </Link>
        </ul>
      </nav>
    </>
  );
}

export default Nav;
