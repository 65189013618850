import "./home.css";

import Nav from "../nav/nav";
import plane from "../../images/plane.svg";
import Carousel from "../carousel/carousel";

function Home() {
  return (
    <>
      <Nav />
      <header>
        <div className="header__left">
          <p className="bold">Using AI to make travel planning easier</p>
          <h1>Let's Find Your Next Travel Destination</h1>
          <div className="header__left__cta">
            <span>
              <p className="bold">Read the explanation on the right</p>
              <img
                src={plane}
                className="header__left__cta__plane"
                alt="Plane icon"
              />
            </span>
          </div>
        </div>
        <div className="header__right">
          <Carousel />
        </div>
      </header>
    </>
  );
}

export default Home;
