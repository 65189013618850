import "./carousel.css";

import find from "../../images/explanation/find.jpg";
import log from "../../images/explanation/log.jpg";
import view from "../../images/explanation/view.jpg";
import advice from "../../images/explanation/advice.jpg";
import interact from "../../images/explanation/interact.jpg";
import start from "../../images/explanation/start.jpg";

function Carousel() {
  return (
    <>
      <div className="slider-wrapper">
        <div className="slider">
          <img id="slide-1" src={find} alt="Find your next destination" />
          <img id="slide-2" src={log} alt="Log visited places" />
          <img id="slide-3" src={view} alt="View logged places" />
          <img id="slide-4" src={advice} alt="Request AI advice" />
          <img id="slide-5" src={interact} alt="Interact with other users" />
          <img id="slide-6" src={start} alt="Start now!" />
        </div>
        <div className="slider-nav">
          <a href="#slide-1"></a>
          <a href="#slide-2"></a>
          <a href="#slide-3"></a>
          <a href="#slide-4"></a>
          <a href="#slide-5"></a>
          <a href="#slide-6"></a>
        </div>
      </div>
    </>
  );
}

export default Carousel;
