import { Navigate, Route, Routes } from "react-router-dom";

import Home from "./components/home/home";
import Footer from "./components/footer/footer";

import "./App.css";

function App() {
  return (
    <>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
